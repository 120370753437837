var formScenarios = {
    product: {
        'hcp': {
            'area-of-interest': [
                { value: '', label: '' },
                { value: 'contact-from-local-organogenesis-representative', label: 'Contact from a local Organogenesis Representative' },
                { value: 'product-information', label: 'Product Information' },
                { value: 'general-question', label: 'General Question' },
                { value: 'reimbursement-inquiry', label: 'Reimbursement Inquiry' },
                { value: 'product-order-delivery-support', label: 'Product Order / Delivery Support' },
                { value: 'patient-assistance-info', label: 'Patient Assistance Info' },
                { value: 'compliance', label: 'Compliance' },
                { value: 'patient', label: 'Patient' },
                { value: 'medical', label: 'Medical' },
                { value: 'regulatory', label: 'Regulatory' },
                { value: 'customer-service', label: 'Customer Service' },
            ],
            scenarios: {
                '': [],
                'contact-from-local-organogenesis-representative': {
                    fields: [
                        { id: '#contact-first-name-container', name: 'firstName', validators: ['required'] },
                        { id: '#contact-last-name-container', name: 'lastName', validators: ['required'] },
                        {
                            id: '#contact-specialty-container',
                            name: 'specialty',
                            variants: [
                                { value: '', label: '' },
                                { value: 'dermatologist', label: 'Dermatologist' },
                                { value: 'podiatrist', label: 'Podiatrist' },
                                { value: 'plastic-surgeon', label: 'Plastic Surgeon' },
                                { value: 'general-surgeon', label: 'General Surgeon' },
                                { value: 'vascular-surgeon', label: 'Vascular Surgeon' },
                                { value: 'gerontologist', label: 'Gerontologist' },
                                { value: 'primary-care-physician', label: 'Primary Care Physician' },
                                { value: 'internal-medicine', label: 'Internal Medicine' },
                                { value: 'nurse', label: 'Nurse' },
                                { value: 'hospitalist', label: 'Hospitalist' },
                                { value: 'orthopedic-surgeon', label: 'Orthopedic Surgeon' },
                                { value: 'spine-orthopedic-surgeon', label: 'Spine Orthopedic Surgeon' },
                                { value: 'neurosurgeon', label: 'Neurosurgeon' },
                                { value: 'pain physician', label: 'Pain Physician' },
                                { value: 'other', label: 'Other' }
                            ],
                            validators: ['required']
                        },
                        { id: '#contact-npi-container', name: 'npi' },
                        { id: '#contact-institution-affiliation-container', name: 'institution' },
                        { id: '#contact-zip-code-container', name: 'zip', validators: ['zip'] },
                        {
                            id: '#contact-reason-for-contact-container',
                            name: 'contactReason',
                            variants: [
                                { value: '', label: '' },
                                { value: 'discussion-with-organogenesis-representative', label: 'Discussion with Organogenesis Representative' },
                                { value: 'literature-request', label: 'Literature request' },
                                { value: 'product-samples', label: 'Product Samples' },
                                { value: 'other', label: 'Other' }
                            ],
                            validators: ['required']
                        },
                        { id: '#contact-preferred-method-of-contact-container', validators: ['required'] },
                        { id: '#contact-sign-up-for-email-options-container', validators: ['required'] }
                    ],
                    agreements: [
                        { name: 'contactRequest', label: 'I would like to be contacted by my local Organogenesis Tissue Regeneration Specialist regarding NuShield.' },
                        { name: 'shareInfo', label: 'I also agree to allow Organogenesis and its agents to collect the information provided and to be contacted by Organogenesis and its agents in the future regarding other products and services.' },
                        { name: 'euEconomicArea', label: 'If You are a Resident of the European Economic Area', id: 'eu-economic-area' },
                        { name: 'personalDataUse', label: 'By checking this box and pressing "Submit", I consent to Organogenesis\' use of my personal data for the purposes identified in the information I have provided in this form.', class: 'eu-economic-area' },
                        { name: 'marketingCommunications', label: 'By checking this box and pressing "Submit", I consent to Organogenesis\' use of my personal information for purposes of sending me marketing communications.', class: 'eu-economic-area' },
                        { name: 'internalAnalysis', label: 'By checking this box and pressing "Submit", I consent to Organogenesis\' use of my personal information for purposes of internal analysis.', class: 'eu-economic-area' },
                        { name: 'transferData', label: 'By checking this box and pressing "Submit" I consent to the transfer of my personal data to Organogenesis for the uses I have consented to above. I understand that Organogenesis is located in the United States, and that the European Commission has not determined that the federal and state laws of the United States provide EEA residents with an adequate level of data protection due to the country\'s surveillance laws. I understand that transfers of personal data to the United States involve risk, and consent to such risk by choosing to send my personal data to Organogenesis.', class: 'eu-economic-area' },
                    ]
                },
                'product-information': {
                    fields: [
                        { id: '#contact-first-name-container', name: 'firstName', validators: ['required'] },
                        { id: '#contact-last-name-container', name: 'lastName', validators: ['required'] },
                        {
                            id: '#contact-specialty-container',
                            name: 'specialty',
                            variants: [
                                { value: '', label: '' },
                                { value: 'dermatologist', label: 'Dermatologist' },
                                { value: 'podiatrist', label: 'Podiatrist' },
                                { value: 'plastic-surgeon', label: 'Plastic Surgeon' },
                                { value: 'general-surgeon', label: 'General Surgeon' },
                                { value: 'vascular-surgeon', label: 'Vascular Surgeon' },
                                { value: 'gerontologist', label: 'Gerontologist' },
                                { value: 'primary-care-physician', label: 'Primary Care Physician' },
                                { value: 'internal-medicine', label: 'Internal Medicine' },
                                { value: 'nurse', label: 'Nurse' },
                                { value: 'hospitalist', label: 'Hospitalist' },
                                { value: 'other', label: 'Other' }
                            ],
                            validators: ['required']
                        },
                        { id: '#contact-npi-container', name: 'npi' },
                        { id: '#contact-institution-affiliation-container', name: 'institution' },
                        { id: '#contact-zip-code-container', name: 'zip', validators: ['zip'] },
                        {
                            id: '#contact-reason-for-contact-container',
                            name: 'contactReason',
                            variants: [
                                { value: '', label: '' },
                                { value: 'discussion-with-organogenesis-representative', label: 'Discussion with Organogenesis Representative' },
                                { value: 'literature-request', label: 'Literature request' },
                                { value: 'product-samples', label: 'Product Samples' },
                                { value: 'other', label: 'Other' }
                            ],
                            validators: ['required']
                        },
                        { id: '#contact-preferred-method-of-contact-container', validators: ['required'] },
                        { id: '#contact-sign-up-for-email-options-container' }
                    ],
                    agreements: [
                        { name: 'contactRequest', label: 'I would like to be contacted by Organogenesis.' },
                        { name: 'shareInfo', label: 'I also agree to allow Organogenesis and its agents to collect the information provided and to be contacted by Organogenesis and its agents in the future regarding other products and services.' },
                        { name: 'euEconomicArea', label: 'If You are a Resident of the European Economic Area', id: 'eu-economic-area' },
                        { name: 'personalDataUse', label: 'By checking this box and pressing "Submit", I consent to Organogenesis\' use of my personal data for the purposes identified in the information I have provided in this form.', class: 'eu-economic-area' },
                        { name: 'marketingCommunications', label: 'By checking this box and pressing "Submit", I consent to Organogenesis\' use of my personal information for purposes of sending me marketing communications.', class: 'eu-economic-area' },
                        { name: 'internalAnalysis', label: 'By checking this box and pressing "Submit", I consent to Organogenesis\' use of my personal information for purposes of internal analysis.', class: 'eu-economic-area' },
                        { name: 'transferData', label: 'By checking this box and pressing "Submit" I consent to the transfer of my personal data to Organogenesis for the uses I have consented to above. I understand that Organogenesis is located in the United States, and that the European Commission has not determined that the federal and state laws of the United States provide EEA residents with an adequate level of data protection due to the country\'s surveillance laws. I understand that transfers of personal data to the United States involve risk, and consent to such risk by choosing to send my personal data to Organogenesis.', class: 'eu-economic-area' },
                    ]
                },
                'general-question': {
                    fields: [
                        { id: '#contact-first-name-container', name: 'firstName', validators: ['required'] },
                        { id: '#contact-last-name-container', name: 'lastName', validators: ['required'] },
                        {
                            id: '#contact-specialty-container',
                            name: 'specialty',
                            variants: [
                                { value: '', label: '' },
                                { value: 'dermatologist', label: 'Dermatologist' },
                                { value: 'podiatrist', label: 'Podiatrist' },
                                { value: 'plastic-surgeon', label: 'Plastic Surgeon' },
                                { value: 'general-surgeon', label: 'General Surgeon' },
                                { value: 'vascular-surgeon', label: 'Vascular Surgeon' },
                                { value: 'gerontologist', label: 'Gerontologist' },
                                { value: 'primary-care-physician', label: 'Primary Care Physician' },
                                { value: 'internal-medicine', label: 'Internal Medicine' },
                                { value: 'nurse', label: 'Nurse' },
                                { value: 'hospitalist', label: 'Hospitalist' },
                                { value: 'other', label: 'Other' }
                            ],
                            validators: ['required']
                        },
                        { id: '#contact-npi-container', name: 'npi' },
                        { id: '#contact-institution-affiliation-container', name: 'institution' },
                        { id: '#contact-zip-code-container', name: 'zip', validators: ['zip'] },
                        {
                            id: '#contact-reason-for-contact-container',
                            name: 'contactReason',
                            variants: [
                                { value: '', label: '' },
                                { value: 'discussion-with-organogenesis-representative', label: 'Discussion with Organogenesis Representative' },
                                { value: 'literature-request', label: 'Literature request' },
                                { value: 'product-samples', label: 'Product Samples' },
                                { value: 'other', label: 'Other' }
                            ],
                            validators: ['required']
                        },
                        { id: '#contact-preferred-method-of-contact-container', validators: ['required'] },
                        { id: '#contact-sign-up-for-email-options-container' }
                    ],
                    agreements: [
                        { name: 'contactRequest', label: 'I would like to be contacted by Organogenesis.' },
                        { name: 'shareInfo', label: 'I also agree to allow Organogenesis and its agents to collect the information provided and to be contacted by Organogenesis and its agents in the future regarding other products and services.' },
                        { name: 'euEconomicArea', label: 'If You are a Resident of the European Economic Area', id: 'eu-economic-area' },
                        { name: 'personalDataUse', label: 'By checking this box and pressing "Submit", I consent to Organogenesis\' use of my personal data for the purposes identified in the information I have provided in this form.', class: 'eu-economic-area' },
                        { name: 'marketingCommunications', label: 'By checking this box and pressing "Submit", I consent to Organogenesis\' use of my personal information for purposes of sending me marketing communications.', class: 'eu-economic-area' },
                        { name: 'internalAnalysis', label: 'By checking this box and pressing "Submit", I consent to Organogenesis\' use of my personal information for purposes of internal analysis.', class: 'eu-economic-area' },
                        { name: 'transferData', label: 'By checking this box and pressing "Submit" I consent to the transfer of my personal data to Organogenesis for the uses I have consented to above. I understand that Organogenesis is located in the United States, and that the European Commission has not determined that the federal and state laws of the United States provide EEA residents with an adequate level of data protection due to the country\'s surveillance laws. I understand that transfers of personal data to the United States involve risk, and consent to such risk by choosing to send my personal data to Organogenesis.', class: 'eu-economic-area' },
                    ]
                },
                'reimbursement-inquiry': {
                    fields: [
                        { id: '#contact-first-name-container', name: 'firstName', validators: ['required'] },
                        { id: '#contact-last-name-container', name: 'lastName', validators: ['required'] },
                        { id: '#contact-institution-affiliation-container', name: 'institution', validators: ['required'] },
                        { id: '#contact-customer-account-number-container', name: 'customerAccountNumber' },
                        { id: '#contact-zip-code-container', name: 'zip', validators: ['zip'] },
                        { id: '#contact-preferred-method-of-contact-container', validators: ['required'] },
                        { id: '#contact-sign-up-for-email-options-container' }
                    ],
                    agreements: [
                        { name: 'contactRequest', label: 'I would like to be contacted by Organogenesis.' },
                        { name: 'shareInfo', label: 'I also agree to allow Organogenesis and its agents to collect the information provided and to be contacted by Organogenesis and its agents in the future regarding other products and services.' },
                        { name: 'euEconomicArea', label: 'If You are a Resident of the European Economic Area', id: 'eu-economic-area' },
                        { name: 'personalDataUse', label: 'By checking this box and pressing "Submit", I consent to Organogenesis\' use of my personal data for the purposes identified in the information I have provided in this form.', class: 'eu-economic-area' },
                        { name: 'marketingCommunications', label: 'By checking this box and pressing "Submit", I consent to Organogenesis\' use of my personal information for purposes of sending me marketing communications.', class: 'eu-economic-area' },
                        { name: 'internalAnalysis', label: 'By checking this box and pressing "Submit", I consent to Organogenesis\' use of my personal information for purposes of internal analysis.', class: 'eu-economic-area' },
                        { name: 'transferData', label: 'By checking this box and pressing "Submit" I consent to the transfer of my personal data to Organogenesis for the uses I have consented to above. I understand that Organogenesis is located in the United States, and that the European Commission has not determined that the federal and state laws of the United States provide EEA residents with an adequate level of data protection due to the country\'s surveillance laws. I understand that transfers of personal data to the United States involve risk, and consent to such risk by choosing to send my personal data to Organogenesis.', class: 'eu-economic-area' },
                    ]
                },
                'product-order-delivery-support': {
                    fields: [
                        { id: '#contact-first-name-container', name: 'firstName', validators: ['required'] },
                        { id: '#contact-last-name-container', name: 'lastName', validators: ['required'] },
                        {
                            id: '#contact-specialty-container',
                            name: 'specialty',
                            variants: [
                                { value: '', label: '' },
                                { value: 'dermatologist', label: 'Dermatologist' },
                                { value: 'podiatrist', label: 'Podiatrist' },
                                { value: 'plastic-surgeon', label: 'Plastic Surgeon' },
                                { value: 'general-surgeon', label: 'General Surgeon' },
                                { value: 'vascular-surgeon', label: 'Vascular Surgeon' },
                                { value: 'gerontologist', label: 'Gerontologist' },
                                { value: 'primary-care-physician', label: 'Primary Care Physician' },
                                { value: 'internal-medicine', label: 'Internal Medicine' },
                                { value: 'nurse', label: 'Nurse' },
                                { value: 'hospitalist', label: 'Hospitalist' },
                                { value: 'other', label: 'Other' }
                            ],
                            validators: ['required']
                        },
                        { id: '#contact-customer-account-number-container', name: 'customerAccountNumber' },
                        { id: '#contact-preferred-method-of-contact-container', validators: ['required'] },
                        { id: '#contact-sign-up-for-email-options-container' }
                    ],
                    agreements: [
                        { name: 'contactRequest', label: 'I would like to be contacted by Organogenesis.' },
                        { name: 'shareInfo', label: 'I also agree to allow Organogenesis and its agents to collect the information provided and to be contacted by Organogenesis and its agents in the future regarding other products and services.' },
                        { name: 'euEconomicArea', label: 'If You are a Resident of the European Economic Area', id: 'eu-economic-area' },
                        { name: 'personalDataUse', label: 'By checking this box and pressing "Submit", I consent to Organogenesis\' use of my personal data for the purposes identified in the information I have provided in this form.', class: 'eu-economic-area' },
                        { name: 'marketingCommunications', label: 'By checking this box and pressing "Submit", I consent to Organogenesis\' use of my personal information for purposes of sending me marketing communications.', class: 'eu-economic-area' },
                        { name: 'internalAnalysis', label: 'By checking this box and pressing "Submit", I consent to Organogenesis\' use of my personal information for purposes of internal analysis.', class: 'eu-economic-area' },
                        { name: 'transferData', label: 'By checking this box and pressing "Submit" I consent to the transfer of my personal data to Organogenesis for the uses I have consented to above. I understand that Organogenesis is located in the United States, and that the European Commission has not determined that the federal and state laws of the United States provide EEA residents with an adequate level of data protection due to the country\'s surveillance laws. I understand that transfers of personal data to the United States involve risk, and consent to such risk by choosing to send my personal data to Organogenesis.', class: 'eu-economic-area' },
                    ]
                },
                'patient-assistance-info': {
                    fields: [
                        { id: '#contact-first-name-container', name: 'firstName', validators: ['required'] },
                        { id: '#contact-last-name-container', name: 'lastName', validators: ['required'] },
                        {
                            id: '#contact-specialty-container',
                            name: 'specialty',
                            variants: [
                                { value: '', label: '' },
                                { value: 'dermatologist', label: 'Dermatologist' },
                                { value: 'podiatrist', label: 'Podiatrist' },
                                { value: 'plastic-surgeon', label: 'Plastic Surgeon' },
                                { value: 'general-surgeon', label: 'General Surgeon' },
                                { value: 'vascular-surgeon', label: 'Vascular Surgeon' },
                                { value: 'gerontologist', label: 'Gerontologist' },
                                { value: 'primary-care-physician', label: 'Primary Care Physician' },
                                { value: 'internal-medicine', label: 'Internal Medicine' },
                                { value: 'nurse', label: 'Nurse' },
                                { value: 'hospitalist', label: 'Hospitalist' },
                                { value: 'other', label: 'Other' }
                            ],
                            validators: ['required']
                        },
                        { id: '#contact-preferred-method-of-contact-container', validators: ['required'] },
                        { id: '#contact-sign-up-for-email-options-container' }
                    ],
                    agreements: [
                        { name: 'contactRequest', label: 'I would like to be contacted by Organogenesis.' },
                        { name: 'shareInfo', label: 'I also agree to allow Organogenesis and its agents to collect the information provided and to be contacted by Organogenesis and its agents in the future regarding other products and services.' },
                        { name: 'euEconomicArea', label: 'If You are a Resident of the European Economic Area', id: 'eu-economic-area' },
                        { name: 'personalDataUse', label: 'By checking this box and pressing "Submit", I consent to Organogenesis\' use of my personal data for the purposes identified in the information I have provided in this form.', class: 'eu-economic-area' },
                        { name: 'marketingCommunications', label: 'By checking this box and pressing "Submit", I consent to Organogenesis\' use of my personal information for purposes of sending me marketing communications.', class: 'eu-economic-area' },
                        { name: 'internalAnalysis', label: 'By checking this box and pressing "Submit", I consent to Organogenesis\' use of my personal information for purposes of internal analysis.', class: 'eu-economic-area' },
                        { name: 'transferData', label: 'By checking this box and pressing "Submit" I consent to the transfer of my personal data to Organogenesis for the uses I have consented to above. I understand that Organogenesis is located in the United States, and that the European Commission has not determined that the federal and state laws of the United States provide EEA residents with an adequate level of data protection due to the country\'s surveillance laws. I understand that transfers of personal data to the United States involve risk, and consent to such risk by choosing to send my personal data to Organogenesis.', class: 'eu-economic-area' },
                    ]
                },
                'compliance': {
                    fields: [
                        { id: '#contact-title-container', name: 'namePrefix', validators: ['required'] },
                        { id: '#contact-first-name-container', name: 'firstName', validators: ['required'] },
                        { id: '#contact-last-name-container', name: 'lastName', validators: ['required'] },
                        { id: '#contact-email-container', name: 'email', validators: ['email'] },
                        { id: '#contact-phone-container', name: 'phone', validators: ['phone'] },
                        { id: '#contact-country-container', name: 'country', validators: ['required'] },
                        { id: '#contact-zip-code-container', name: 'zip', validators: ['zip'] },
                    ],
                    disclaimers: [
                        '<div>General Compliance Helpline & Email <a href="tel:800-598-6936">800-598-6936</a>; <a href="mailto:compliance@organo.com">compliance@organo.com</a></div><br /><div>- <strong>Note</strong>: Inquiries sent to this email address will not be done so anonymously.</div><br /><div>Anonymous Compliance Helpline: <a href="tel:833-880-0007">833-880-0007</a>; <a href="mailto:reports@lighthouse-services.com">reports@lighthouse-services.com</a>; <a target="_blank" href="https://lighthouse-services.com/organo">www.lighthouse-services.com/organo</a></div>'
                    ],
                    agreements: [
                        { name: 'contactRequest', label: 'I would like to be contacted by Organogenesis.' },
                        { name: 'shareInfo', label: 'I also agree to allow Organogenesis and its agents to collect the information provided and to be contacted by Organogenesis and its agents in the future regarding other products and services.' },
                        { name: 'euEconomicArea', label: 'If You are a Resident of the European Economic Area', id: 'eu-economic-area' },
                        { name: 'personalDataUse', label: 'By checking this box and pressing "Submit", I consent to Organogenesis\' use of my personal data for the purposes identified in the information I have provided in this form.', class: 'eu-economic-area' },
                        { name: 'marketingCommunications', label: 'By checking this box and pressing "Submit", I consent to Organogenesis\' use of my personal information for purposes of sending me marketing communications.', class: 'eu-economic-area' },
                        { name: 'internalAnalysis', label: 'By checking this box and pressing "Submit", I consent to Organogenesis\' use of my personal information for purposes of internal analysis.', class: 'eu-economic-area' },
                        { name: 'transferData', label: 'By checking this box and pressing "Submit" I consent to the transfer of my personal data to Organogenesis for the uses I have consented to above. I understand that Organogenesis is located in the United States, and that the European Commission has not determined that the federal and state laws of the United States provide EEA residents with an adequate level of data protection due to the country\'s surveillance laws. I understand that transfers of personal data to the United States involve risk, and consent to such risk by choosing to send my personal data to Organogenesis.', class: 'eu-economic-area' },
                    ]
                },
                'patient': {
                    fields: [
                        { id: '#contact-title-container', name: 'namePrefix', validators: ['required'] },
                        { id: '#contact-first-name-container', name: 'firstName', validators: ['required'] },
                        { id: '#contact-last-name-container', name: 'lastName', validators: ['required'] },
                        { id: '#contact-email-container', name: 'email', validators: ['email'] },
                        { id: '#contact-phone-container', name: 'phone', validators: ['phone'] },
                        { id: '#contact-country-container', name: 'country', validators: ['required'] },
                        { id: '#contact-zip-code-container', name: 'zip', validators: ['zip'] },
                    ],
                    agreements: [
                        { name: 'contactRequest', label: 'I would like to be contacted by Organogenesis.' },
                        { name: 'shareInfo', label: 'I authorize Organogenesis and those working with or on behalf of Organogenesis  to contact me via email, mail, or phone to conduct marketing activities, which includes, but is not limited to, providing me with educational and promotional materials, information on Organogenesis products and patient support services related to my treatment or health condition and/or to conduct market research activities, which includes contacting me to participate in various activities such as surveys, focus groups, or interviews which would be funded by Organogenesis. I understand that Organogenesis will not release my personally identifiable information to any party, except as provided in this authorization or as permitted by applicable law, without first obtaining my (or my authorized representative’s) separate written consent. I understand that I may revoke this authorization at any time by contacting Organogenesis at <a href="tel:+1 888 432 5232">+1 888 432 5232</a> or in writing at Organogenesis 85 Dan Road, Canton, MA 02021.' },
                        { name: 'euEconomicArea', label: 'If You are a Resident of the European Economic Area', id: 'eu-economic-area' },
                        { name: 'personalDataUse', label: 'By checking this box and pressing "Submit", I consent to Organogenesis\' use of my personal data for the purposes identified in the information I have provided in this form.', class: 'eu-economic-area' },
                        { name: 'marketingCommunications', label: 'By checking this box and pressing "Submit", I consent to Organogenesis\' use of my personal information for purposes of sending me marketing communications.', class: 'eu-economic-area' },
                        { name: 'internalAnalysis', label: 'By checking this box and pressing "Submit", I consent to Organogenesis\' use of my personal information for purposes of internal analysis.', class: 'eu-economic-area' },
                        { name: 'transferData', label: 'By checking this box and pressing "Submit" I consent to the transfer of my personal data to Organogenesis for the uses I have consented to above. I understand that Organogenesis is located in the United States, and that the European Commission has not determined that the federal and state laws of the United States provide EEA residents with an adequate level of data protection due to the country\'s surveillance laws. I understand that transfers of personal data to the United States involve risk, and consent to such risk by choosing to send my personal data to Organogenesis.', class: 'eu-economic-area' },
                    ]
                },
                'medical': {
                    fields: [
                        { id: '#contact-title-container', name: 'namePrefix', validators: ['required'] },
                        { id: '#contact-first-name-container', name: 'firstName', validators: ['required'] },
                        { id: '#contact-last-name-container', name: 'lastName', validators: ['required'] },
                        { id: '#contact-email-container', name: 'email', validators: ['email'] },
                        { id: '#contact-phone-container', name: 'phone', validators: ['phone'] },
                        { id: '#contact-country-container', name: 'country', validators: ['required'] },
                        { id: '#contact-zip-code-container', name: 'zip', validators: ['zip'] },
                    ],
                    agreements: [
                        { name: 'contactRequest', label: 'I would like to be contacted by Organogenesis.' },
                        { name: 'shareInfo', label: 'I also agree to allow Organogenesis and its agents to collect the information provided and to be contacted by Organogenesis and its agents in the future regarding other products and services.' },
                        { name: 'euEconomicArea', label: 'If You are a Resident of the European Economic Area', id: 'eu-economic-area' },
                        { name: 'personalDataUse', label: 'By checking this box and pressing "Submit", I consent to Organogenesis\' use of my personal data for the purposes identified in the information I have provided in this form.', class: 'eu-economic-area' },
                        { name: 'marketingCommunications', label: 'By checking this box and pressing "Submit", I consent to Organogenesis\' use of my personal information for purposes of sending me marketing communications.', class: 'eu-economic-area' },
                        { name: 'internalAnalysis', label: 'By checking this box and pressing "Submit", I consent to Organogenesis\' use of my personal information for purposes of internal analysis.', class: 'eu-economic-area' },
                        { name: 'transferData', label: 'By checking this box and pressing "Submit" I consent to the transfer of my personal data to Organogenesis for the uses I have consented to above. I understand that Organogenesis is located in the United States, and that the European Commission has not determined that the federal and state laws of the United States provide EEA residents with an adequate level of data protection due to the country\'s surveillance laws. I understand that transfers of personal data to the United States involve risk, and consent to such risk by choosing to send my personal data to Organogenesis.', class: 'eu-economic-area' },
                    ]
                },
                'regulatory': {
                    fields: [
                        { id: '#contact-title-container', name: 'namePrefix', validators: ['required'] },
                        { id: '#contact-first-name-container', name: 'firstName', validators: ['required'] },
                        { id: '#contact-last-name-container', name: 'lastName', validators: ['required'] },
                        { id: '#contact-email-container', name: 'email', validators: ['email'] },
                        { id: '#contact-phone-container', name: 'phone', validators: ['phone'] },
                        { id: '#contact-country-container', name: 'country', validators: ['required'] },
                        { id: '#contact-zip-code-container', name: 'zip', validators: ['zip'] },
                    ],
                    agreements: [
                        { name: 'contactRequest', label: 'I would like to be contacted by Organogenesis.' },
                        { name: 'shareInfo', label: 'I also agree to allow Organogenesis and its agents to collect the information provided and to be contacted by Organogenesis and its agents in the future regarding other products and services.' },
                        { name: 'euEconomicArea', label: 'If You are a Resident of the European Economic Area', id: 'eu-economic-area' },
                        { name: 'personalDataUse', label: 'By checking this box and pressing "Submit", I consent to Organogenesis\' use of my personal data for the purposes identified in the information I have provided in this form.', class: 'eu-economic-area' },
                        { name: 'marketingCommunications', label: 'By checking this box and pressing "Submit", I consent to Organogenesis\' use of my personal information for purposes of sending me marketing communications.', class: 'eu-economic-area' },
                        { name: 'internalAnalysis', label: 'By checking this box and pressing "Submit", I consent to Organogenesis\' use of my personal information for purposes of internal analysis.', class: 'eu-economic-area' },
                        { name: 'transferData', label: 'By checking this box and pressing "Submit" I consent to the transfer of my personal data to Organogenesis for the uses I have consented to above. I understand that Organogenesis is located in the United States, and that the European Commission has not determined that the federal and state laws of the United States provide EEA residents with an adequate level of data protection due to the country\'s surveillance laws. I understand that transfers of personal data to the United States involve risk, and consent to such risk by choosing to send my personal data to Organogenesis.', class: 'eu-economic-area' },
                    ]
                },
                'customer-service': {
                    fields: [
                        { id: '#contact-title-container', name: 'namePrefix', validators: ['required'] },
                        { id: '#contact-first-name-container', name: 'firstName', validators: ['required'] },
                        { id: '#contact-last-name-container', name: 'lastName', validators: ['required'] },
                        { id: '#contact-institution-affiliation-container', name: 'institution', validators: ['required'] },
                        { id: '#contact-customer-account-number-container', name: 'customerAccountNumber' },
                        { id: '#contact-email-container', name: 'email', validators: ['email'] },
                        { id: '#contact-phone-container', name: 'phone', validators: ['phone'] },
                        { id: '#contact-zip-code-container', name: 'zip', validators: ['zip'] },
                    ],
                    agreements: [
                        { name: 'contactRequest', label: 'I would like to be contacted by Organogenesis.' },
                        { name: 'shareInfo', label: 'I also agree to allow Organogenesis and its agents to collect the information provided and to be contacted by Organogenesis and its agents in the future regarding other products and services.' },
                        { name: 'euEconomicArea', label: 'If You are a Resident of the European Economic Area', id: 'eu-economic-area' },
                        { name: 'personalDataUse', label: 'By checking this box and pressing "Submit", I consent to Organogenesis\' use of my personal data for the purposes identified in the information I have provided in this form.', class: 'eu-economic-area' },
                        { name: 'marketingCommunications', label: 'By checking this box and pressing "Submit", I consent to Organogenesis\' use of my personal information for purposes of sending me marketing communications.', class: 'eu-economic-area' },
                        { name: 'internalAnalysis', label: 'By checking this box and pressing "Submit", I consent to Organogenesis\' use of my personal information for purposes of internal analysis.', class: 'eu-economic-area' },
                        { name: 'transferData', label: 'By checking this box and pressing "Submit" I consent to the transfer of my personal data to Organogenesis for the uses I have consented to above. I understand that Organogenesis is located in the United States, and that the European Commission has not determined that the federal and state laws of the United States provide EEA residents with an adequate level of data protection due to the country\'s surveillance laws. I understand that transfers of personal data to the United States involve risk, and consent to such risk by choosing to send my personal data to Organogenesis.', class: 'eu-economic-area' },
                    ]
                },
            },
        },
        'not-hcp': {
            'area-of-interest': [
                { value: '', label: '' },
                { value: 'product-information', label: 'Product Information' },
                { value: 'general-question', label: 'General Question' },
                { value: 'compliance', label: 'Compliance' },
                { value: 'patient', label: 'Patient' },
                { value: 'medical', label: 'Medical' },
                { value: 'regulatory', label: 'Regulatory' },
            ],
            scenarios: {
                '': [],
                'product-information': {
                    fields: [
                        { id: '#contact-first-name-container', name: 'firstName', validators: ['required'] },
                        { id: '#contact-last-name-container', name: 'lastName', validators: ['required'] },
                        {
                            id: '#contact-specialty-container',
                            name: 'specialty',
                            variants: [
                                { value: '', label: '' },
                                { value: 'dermatologist', label: 'Dermatologist' },
                                { value: 'podiatrist', label: 'Podiatrist' },
                                { value: 'plastic-surgeon', label: 'Plastic Surgeon' },
                                { value: 'general-surgeon', label: 'General Surgeon' },
                                { value: 'vascular-surgeon', label: 'Vascular Surgeon' },
                                { value: 'gerontologist', label: 'Gerontologist' },
                                { value: 'primary-care-physician', label: 'Primary Care Physician' },
                                { value: 'internal-medicine', label: 'Internal Medicine' },
                                { value: 'nurse', label: 'Nurse' },
                                { value: 'hospitalist', label: 'Hospitalist' },
                                { value: 'other', label: 'Other' }
                            ],
                            validators: ['required']
                        },
                        { id: '#contact-npi-container', name: 'npi' },
                        { id: '#contact-institution-affiliation-container', name: 'institution' },
                        { id: '#contact-country-container', name: 'country', validators: ['required'] },
                        { id: '#contact-zip-code-container', name: 'zip', validators: ['zip'] },
                        {
                            id: '#contact-reason-for-contact-container',
                            name: 'contactReason',
                            variants: [
                                { value: '', label: '' },
                                { value: 'literature-request', label: 'Literature request' },
                                { value: 'product-samples', label: 'Product Samples' },
                                { value: 'other', label: 'Other' }
                            ],
                            validators: ['required']
                        },
                        { id: '#contact-preferred-method-of-contact-container', validators: ['required'] },
                        { id: '#contact-sign-up-for-email-options-container' }
                    ],
                    agreements: [
                        { name: 'contactRequest', label: 'I would like to be contacted by Organogenesis.' },
                        { name: 'shareInfo', label: 'I also agree to allow Organogenesis and its agents to collect the information provided and to be contacted by Organogenesis and its agents in the future regarding other products and services.' },
                        { name: 'euEconomicArea', label: 'If You are a Resident of the European Economic Area', id: 'eu-economic-area' },
                        { name: 'personalDataUse', label: 'By checking this box and pressing "Submit", I consent to Organogenesis\' use of my personal data for the purposes identified in the information I have provided in this form.', class: 'eu-economic-area' },
                        { name: 'marketingCommunications', label: 'By checking this box and pressing "Submit", I consent to Organogenesis\' use of my personal information for purposes of sending me marketing communications.', class: 'eu-economic-area' },
                        { name: 'internalAnalysis', label: 'By checking this box and pressing "Submit", I consent to Organogenesis\' use of my personal information for purposes of internal analysis.', class: 'eu-economic-area' },
                        { name: 'transferData', label: 'By checking this box and pressing "Submit" I consent to the transfer of my personal data to Organogenesis for the uses I have consented to above. I understand that Organogenesis is located in the United States, and that the European Commission has not determined that the federal and state laws of the United States provide EEA residents with an adequate level of data protection due to the country\'s surveillance laws. I understand that transfers of personal data to the United States involve risk, and consent to such risk by choosing to send my personal data to Organogenesis.', class: 'eu-economic-area' },
                    ]
                },
                'general-question': {
                    fields: [
                        { id: '#contact-first-name-container', name: 'firstName', validators: ['required'] },
                        { id: '#contact-last-name-container', name: 'lastName', validators: ['required'] },
                        {
                            id: '#contact-specialty-container',
                            name: 'specialty',
                            variants: [
                                { value: '', label: '' },
                                { value: 'dermatologist', label: 'Dermatologist' },
                                { value: 'podiatrist', label: 'Podiatrist' },
                                { value: 'plastic-surgeon', label: 'Plastic Surgeon' },
                                { value: 'general-surgeon', label: 'General Surgeon' },
                                { value: 'vascular-surgeon', label: 'Vascular Surgeon' },
                                { value: 'gerontologist', label: 'Gerontologist' },
                                { value: 'primary-care-physician', label: 'Primary Care Physician' },
                                { value: 'internal-medicine', label: 'Internal Medicine' },
                                { value: 'nurse', label: 'Nurse' },
                                { value: 'hospitalist', label: 'Hospitalist' },
                                { value: 'other', label: 'Other' }
                            ],
                            validators: ['required']
                        },
                        { id: '#contact-npi-container', name: 'npi' },
                        { id: '#contact-institution-affiliation-container', name: 'institution' },
                        { id: '#contact-country-container', name: 'country', validators: ['required'] },
                        { id: '#contact-zip-code-container', name: 'zip', validators: ['zip'] },
                        {
                            id: '#contact-reason-for-contact-container',
                            name: 'contactReason',
                            variants: [
                                { value: '', label: '' },
                                { value: 'literature-request', label: 'Literature request' },
                                { value: 'product-samples', label: 'Product Samples' },
                                { value: 'other', label: 'Other' }
                            ],
                            validators: ['required']
                        },
                        { id: '#contact-preferred-method-of-contact-container', validators: ['required'] },
                        { id: '#contact-sign-up-for-email-options-container' }
                    ],
                    agreements: [
                        { name: 'contactRequest', label: 'I would like to be contacted by Organogenesis.' },
                        { name: 'shareInfo', label: 'I also agree to allow Organogenesis and its agents to collect the information provided and to be contacted by Organogenesis and its agents in the future regarding other products and services.' },
                        { name: 'euEconomicArea', label: 'If You are a Resident of the European Economic Area', id: 'eu-economic-area' },
                        { name: 'personalDataUse', label: 'By checking this box and pressing "Submit", I consent to Organogenesis\' use of my personal data for the purposes identified in the information I have provided in this form.', class: 'eu-economic-area' },
                        { name: 'marketingCommunications', label: 'By checking this box and pressing "Submit", I consent to Organogenesis\' use of my personal information for purposes of sending me marketing communications.', class: 'eu-economic-area' },
                        { name: 'internalAnalysis', label: 'By checking this box and pressing "Submit", I consent to Organogenesis\' use of my personal information for purposes of internal analysis.', class: 'eu-economic-area' },
                        { name: 'transferData', label: 'By checking this box and pressing "Submit" I consent to the transfer of my personal data to Organogenesis for the uses I have consented to above. I understand that Organogenesis is located in the United States, and that the European Commission has not determined that the federal and state laws of the United States provide EEA residents with an adequate level of data protection due to the country\'s surveillance laws. I understand that transfers of personal data to the United States involve risk, and consent to such risk by choosing to send my personal data to Organogenesis.', class: 'eu-economic-area' },
                    ]
                },
                'compliance': {
                    fields: [
                        { id: '#contact-title-container', name: 'namePrefix', validators: ['required'] },
                        { id: '#contact-first-name-container', name: 'firstName', validators: ['required'] },
                        { id: '#contact-last-name-container', name: 'lastName', validators: ['required'] },
                        { id: '#contact-email-container', name: 'email', validators: ['email'] },
                        { id: '#contact-phone-container', name: 'phone', validators: ['phone'] },
                        { id: '#contact-country-container', name: 'country', validators: ['required'] },
                        { id: '#contact-zip-code-container', name: 'zip', validators: ['zip'] },
                    ],
                    disclaimers: [
                        '<div>General Compliance Helpline & Email <a href="tel:800-598-6936">800-598-6936</a>; <a href="mailto:compliance@organo.com">compliance@organo.com</a></div><br /><div>- <strong>Note</strong>: Inquiries sent to this email address will not be done so anonymously.</div><br /><div>Anonymous Compliance Helpline: <a href="tel:833-880-0007">833-880-0007</a>; <a href="mailto:reports@lighthouse-services.com">reports@lighthouse-services.com</a>; <a target="_blank" href="https://lighthouse-services.com/organo">www.lighthouse-services.com/organo</a></div>'
                    ],
                    agreements: [
                        { name: 'contactRequest', label: 'I would like to be contacted by Organogenesis.' },
                        { name: 'shareInfo', label: 'I also agree to allow Organogenesis and its agents to collect the information provided and to be contacted by Organogenesis and its agents in the future regarding other products and services.' },
                        { name: 'euEconomicArea', label: 'If You are a Resident of the European Economic Area', id: 'eu-economic-area' },
                        { name: 'personalDataUse', label: 'By checking this box and pressing "Submit", I consent to Organogenesis\' use of my personal data for the purposes identified in the information I have provided in this form.', class: 'eu-economic-area' },
                        { name: 'marketingCommunications', label: 'By checking this box and pressing "Submit", I consent to Organogenesis\' use of my personal information for purposes of sending me marketing communications.', class: 'eu-economic-area' },
                        { name: 'internalAnalysis', label: 'By checking this box and pressing "Submit", I consent to Organogenesis\' use of my personal information for purposes of internal analysis.', class: 'eu-economic-area' },
                        { name: 'transferData', label: 'By checking this box and pressing "Submit" I consent to the transfer of my personal data to Organogenesis for the uses I have consented to above. I understand that Organogenesis is located in the United States, and that the European Commission has not determined that the federal and state laws of the United States provide EEA residents with an adequate level of data protection due to the country\'s surveillance laws. I understand that transfers of personal data to the United States involve risk, and consent to such risk by choosing to send my personal data to Organogenesis.', class: 'eu-economic-area' },
                    ]
                },
                'patient': {
                    fields: [
                        { id: '#contact-title-container', name: 'namePrefix', validators: ['required'] },
                        { id: '#contact-first-name-container', name: 'firstName', validators: ['required'] },
                        { id: '#contact-last-name-container', name: 'lastName', validators: ['required'] },
                        { id: '#contact-email-container', name: 'email', validators: ['email'] },
                        { id: '#contact-phone-container', name: 'phone', validators: ['phone'] },
                        { id: '#contact-country-container', name: 'country', validators: ['required'] },
                        { id: '#contact-zip-code-container', name: 'zip', validators: ['zip'] },
                    ],
                    agreements: [
                        { name: 'contactRequest', label: 'I would like to be contacted by Organogenesis.' },
                        { name: 'shareInfo', label: 'I authorize Organogenesis and those working with or on behalf of Organogenesis  to contact me via email, mail, or phone to conduct marketing activities, which includes, but is not limited to, providing me with educational and promotional materials, information on Organogenesis products and patient support services related to my treatment or health condition and/or to conduct market research activities, which includes contacting me to participate in various activities such as surveys, focus groups, or interviews which would be funded by Organogenesis. I understand that Organogenesis will not release my personally identifiable information to any party, except as provided in this authorization or as permitted by applicable law, without first obtaining my (or my authorized representative’s) separate written consent. I understand that I may revoke this authorization at any time by contacting Organogenesis at <a href="tel:+1 888 432 5232">+1 888 432 5232</a> or in writing at Organogenesis 85 Dan Road, Canton, MA 02021.' },
                        { name: 'euEconomicArea', label: 'If You are a Resident of the European Economic Area', id: 'eu-economic-area' },
                        { name: 'personalDataUse', label: 'By checking this box and pressing "Submit", I consent to Organogenesis\' use of my personal data for the purposes identified in the information I have provided in this form.', class: 'eu-economic-area' },
                        { name: 'marketingCommunications', label: 'By checking this box and pressing "Submit", I consent to Organogenesis\' use of my personal information for purposes of sending me marketing communications.', class: 'eu-economic-area' },
                        { name: 'internalAnalysis', label: 'By checking this box and pressing "Submit", I consent to Organogenesis\' use of my personal information for purposes of internal analysis.', class: 'eu-economic-area' },
                        { name: 'transferData', label: 'By checking this box and pressing "Submit" I consent to the transfer of my personal data to Organogenesis for the uses I have consented to above. I understand that Organogenesis is located in the United States, and that the European Commission has not determined that the federal and state laws of the United States provide EEA residents with an adequate level of data protection due to the country\'s surveillance laws. I understand that transfers of personal data to the United States involve risk, and consent to such risk by choosing to send my personal data to Organogenesis.', class: 'eu-economic-area' },
                    ]
                },
                'medical': {
                    fields: [
                        { id: '#contact-title-container', name: 'namePrefix', validators: ['required'] },
                        { id: '#contact-first-name-container', name: 'firstName', validators: ['required'] },
                        { id: '#contact-last-name-container', name: 'lastName', validators: ['required'] },
                        { id: '#contact-email-container', name: 'email', validators: ['email'] },
                        { id: '#contact-phone-container', name: 'phone', validators: ['phone'] },
                        { id: '#contact-country-container', name: 'country', validators: ['required'] },
                        { id: '#contact-zip-code-container', name: 'zip', validators: ['zip'] },
                    ],
                    agreements: [
                        { name: 'contactRequest', label: 'I would like to be contacted by Organogenesis.' },
                        { name: 'shareInfo', label: 'I also agree to allow Organogenesis and its agents to collect the information provided and to be contacted by Organogenesis and its agents in the future regarding other products and services.' },
                        { name: 'euEconomicArea', label: 'If You are a Resident of the European Economic Area', id: 'eu-economic-area' },
                        { name: 'personalDataUse', label: 'By checking this box and pressing "Submit", I consent to Organogenesis\' use of my personal data for the purposes identified in the information I have provided in this form.', class: 'eu-economic-area' },
                        { name: 'marketingCommunications', label: 'By checking this box and pressing "Submit", I consent to Organogenesis\' use of my personal information for purposes of sending me marketing communications.', class: 'eu-economic-area' },
                        { name: 'internalAnalysis', label: 'By checking this box and pressing "Submit", I consent to Organogenesis\' use of my personal information for purposes of internal analysis.', class: 'eu-economic-area' },
                        { name: 'transferData', label: 'By checking this box and pressing "Submit" I consent to the transfer of my personal data to Organogenesis for the uses I have consented to above. I understand that Organogenesis is located in the United States, and that the European Commission has not determined that the federal and state laws of the United States provide EEA residents with an adequate level of data protection due to the country\'s surveillance laws. I understand that transfers of personal data to the United States involve risk, and consent to such risk by choosing to send my personal data to Organogenesis.', class: 'eu-economic-area' },
                    ]
                },
                'regulatory': {
                    fields: [
                        { id: '#contact-title-container', name: 'namePrefix', validators: ['required'] },
                        { id: '#contact-first-name-container', name: 'firstName', validators: ['required'] },
                        { id: '#contact-last-name-container', name: 'lastName', validators: ['required'] },
                        { id: '#contact-email-container', name: 'email', validators: ['email'] },
                        { id: '#contact-phone-container', name: 'phone', validators: ['phone'] },
                        { id: '#contact-country-container', name: 'country', validators: ['required'] },
                        { id: '#contact-zip-code-container', name: 'zip', validators: ['zip'] },
                    ],
                    agreements: [
                        { name: 'contactRequest', label: 'I would like to be contacted by Organogenesis.' },
                        { name: 'shareInfo', label: 'I also agree to allow Organogenesis and its agents to collect the information provided and to be contacted by Organogenesis and its agents in the future regarding other products and services.' },
                        { name: 'euEconomicArea', label: 'If You are a Resident of the European Economic Area', id: 'eu-economic-area' },
                        { name: 'personalDataUse', label: 'By checking this box and pressing "Submit", I consent to Organogenesis\' use of my personal data for the purposes identified in the information I have provided in this form.', class: 'eu-economic-area' },
                        { name: 'marketingCommunications', label: 'By checking this box and pressing "Submit", I consent to Organogenesis\' use of my personal information for purposes of sending me marketing communications.', class: 'eu-economic-area' },
                        { name: 'internalAnalysis', label: 'By checking this box and pressing "Submit", I consent to Organogenesis\' use of my personal information for purposes of internal analysis.', class: 'eu-economic-area' },
                        { name: 'transferData', label: 'By checking this box and pressing "Submit" I consent to the transfer of my personal data to Organogenesis for the uses I have consented to above. I understand that Organogenesis is located in the United States, and that the European Commission has not determined that the federal and state laws of the United States provide EEA residents with an adequate level of data protection due to the country\'s surveillance laws. I understand that transfers of personal data to the United States involve risk, and consent to such risk by choosing to send my personal data to Organogenesis.', class: 'eu-economic-area' },
                    ]
                },
            },
        },
    },
    corporate: {
        'hcp': {
            'area-of-interest': [
                { value: '', label: '' },
                { value: 'reimbursement-inquiry', label: 'Reimbursement Inquiry' },
                { value: 'compliance', label: 'Compliance' },
                { value: 'corporate', label: 'Corporate' },
                { value: 'investor', label: 'Investor' },
                { value: 'press', label: 'Press' },
                { value: 'patient', label: 'Patient' },
                { value: 'medical', label: 'Medical' },
                { value: 'regulatory', label: 'Regulatory' },
                { value: 'customer-service', label: 'Customer Service' },
            ],
            scenarios: {
                '': [],
                'reimbursement-inquiry': {
                    fields: [
                        { id: '#contact-first-name-container', name: 'firstName', validators: ['required'] },
                        { id: '#contact-last-name-container', name: 'lastName', validators: ['required'] },
                        { id: '#contact-institution-affiliation-container', name: 'institution', validators: ['required'] },
                        { id: '#contact-customer-account-number-container', name: 'customerAccountNumber' },
                        { id: '#contact-zip-code-container', name: 'zip', validators: ['zip'] },
                        { id: '#contact-preferred-method-of-contact-container', validators: ['required'] },
                        { id: '#contact-sign-up-for-email-options-container' }
                    ],
                    agreements: [
                        { name: 'contactRequest', label: 'I would like to be contacted by Organogenesis.' },
                        { name: 'shareInfo', label: 'I also agree to allow Organogenesis and its agents to collect the information provided and to be contacted by Organogenesis and its agents in the future regarding other products and services.' },
                        { name: 'euEconomicArea', label: 'If You are a Resident of the European Economic Area', id: 'eu-economic-area' },
                        { name: 'personalDataUse', label: 'By checking this box and pressing "Submit", I consent to Organogenesis\' use of my personal data for the purposes identified in the information I have provided in this form.', class: 'eu-economic-area' },
                        { name: 'marketingCommunications', label: 'By checking this box and pressing "Submit", I consent to Organogenesis\' use of my personal information for purposes of sending me marketing communications.', class: 'eu-economic-area' },
                        { name: 'internalAnalysis', label: 'By checking this box and pressing "Submit", I consent to Organogenesis\' use of my personal information for purposes of internal analysis.', class: 'eu-economic-area' },
                        { name: 'transferData', label: 'By checking this box and pressing "Submit" I consent to the transfer of my personal data to Organogenesis for the uses I have consented to above. I understand that Organogenesis is located in the United States, and that the European Commission has not determined that the federal and state laws of the United States provide EEA residents with an adequate level of data protection due to the country\'s surveillance laws. I understand that transfers of personal data to the United States involve risk, and consent to such risk by choosing to send my personal data to Organogenesis.', class: 'eu-economic-area' },
                    ]
                },
                'compliance': {
                    fields: [
                        { id: '#contact-title-container', name: 'namePrefix', validators: ['required'] },
                        { id: '#contact-first-name-container', name: 'firstName', validators: ['required'] },
                        { id: '#contact-last-name-container', name: 'lastName', validators: ['required'] },
                        { id: '#contact-email-container', name: 'email', validators: ['email'] },
                        { id: '#contact-phone-container', name: 'phone', validators: ['phone'] },
                        { id: '#contact-country-container', name: 'country', validators: ['required'] },
                        { id: '#contact-zip-code-container', name: 'zip', validators: ['zip'] },
                    ],
                    disclaimers: [
                        '<div>General Compliance Helpline & Email <a href="tel:800-598-6936">800-598-6936</a>; <a href="mailto:compliance@organo.com">compliance@organo.com</a></div><br /><div>- <strong>Note</strong>: Inquiries sent to this email address will not be done so anonymously.</div><br /><div>Anonymous Compliance Helpline: <a href="tel:833-880-0007">833-880-0007</a>; <a href="mailto:reports@lighthouse-services.com">reports@lighthouse-services.com</a>; <a target="_blank" href="https://lighthouse-services.com/organo">www.lighthouse-services.com/organo</a></div>'
                    ],
                    agreements: [
                        { name: 'contactRequest', label: 'I would like to be contacted by Organogenesis.' },
                        { name: 'shareInfo', label: 'I also agree to allow Organogenesis and its agents to collect the information provided and to be contacted by Organogenesis and its agents in the future regarding other products and services.' },
                        { name: 'euEconomicArea', label: 'If You are a Resident of the European Economic Area', id: 'eu-economic-area' },
                        { name: 'personalDataUse', label: 'By checking this box and pressing "Submit", I consent to Organogenesis\' use of my personal data for the purposes identified in the information I have provided in this form.', class: 'eu-economic-area' },
                        { name: 'marketingCommunications', label: 'By checking this box and pressing "Submit", I consent to Organogenesis\' use of my personal information for purposes of sending me marketing communications.', class: 'eu-economic-area' },
                        { name: 'internalAnalysis', label: 'By checking this box and pressing "Submit", I consent to Organogenesis\' use of my personal information for purposes of internal analysis.', class: 'eu-economic-area' },
                        { name: 'transferData', label: 'By checking this box and pressing "Submit" I consent to the transfer of my personal data to Organogenesis for the uses I have consented to above. I understand that Organogenesis is located in the United States, and that the European Commission has not determined that the federal and state laws of the United States provide EEA residents with an adequate level of data protection due to the country\'s surveillance laws. I understand that transfers of personal data to the United States involve risk, and consent to such risk by choosing to send my personal data to Organogenesis.', class: 'eu-economic-area' },
                    ]
                },
                'corporate': {
                    fields: [
                        { id: '#contact-title-container', name: 'namePrefix', validators: ['required'] },
                        { id: '#contact-first-name-container', name: 'firstName', validators: ['required'] },
                        { id: '#contact-last-name-container', name: 'lastName', validators: ['required'] },
                        { id: '#contact-email-container', name: 'email', validators: ['email'] },
                        { id: '#contact-phone-container', name: 'phone', validators: ['phone'] },
                        { id: '#contact-country-container', name: 'country', validators: ['required'] },
                        { id: '#contact-zip-code-container', name: 'zip', validators: ['zip'] },
                    ],
                    agreements: [
                        { name: 'contactRequest', label: 'I would like to be contacted by Organogenesis.' },
                        { name: 'shareInfo', label: 'I also agree to allow Organogenesis and its agents to collect the information provided and to be contacted by Organogenesis and its agents in the future regarding other products and services.' },
                        { name: 'euEconomicArea', label: 'If You are a Resident of the European Economic Area', id: 'eu-economic-area' },
                        { name: 'personalDataUse', label: 'By checking this box and pressing "Submit", I consent to Organogenesis\' use of my personal data for the purposes identified in the information I have provided in this form.', class: 'eu-economic-area' },
                        { name: 'marketingCommunications', label: 'By checking this box and pressing "Submit", I consent to Organogenesis\' use of my personal information for purposes of sending me marketing communications.', class: 'eu-economic-area' },
                        { name: 'internalAnalysis', label: 'By checking this box and pressing "Submit", I consent to Organogenesis\' use of my personal information for purposes of internal analysis.', class: 'eu-economic-area' },
                        { name: 'transferData', label: 'By checking this box and pressing "Submit" I consent to the transfer of my personal data to Organogenesis for the uses I have consented to above. I understand that Organogenesis is located in the United States, and that the European Commission has not determined that the federal and state laws of the United States provide EEA residents with an adequate level of data protection due to the country\'s surveillance laws. I understand that transfers of personal data to the United States involve risk, and consent to such risk by choosing to send my personal data to Organogenesis.', class: 'eu-economic-area' },
                    ]
                },
                'investor': {
                    fields: [
                        { id: '#contact-title-container', name: 'namePrefix', validators: ['required'] },
                        { id: '#contact-first-name-container', name: 'firstName', validators: ['required'] },
                        { id: '#contact-last-name-container', name: 'lastName', validators: ['required'] },
                        { id: '#contact-email-container', name: 'email', validators: ['email'] },
                        { id: '#contact-phone-container', name: 'phone', validators: ['phone'] },
                        { id: '#contact-country-container', name: 'country', validators: ['required'] },
                        { id: '#contact-zip-code-container', name: 'zip', validators: ['zip'] },
                    ],
                    agreements: [
                        { name: 'contactRequest', label: 'I would like to be contacted by Organogenesis.' },
                        { name: 'shareInfo', label: 'I also agree to allow Organogenesis and its agents to collect the information provided and to be contacted by Organogenesis and its agents in the future regarding other products and services.' },
                        { name: 'euEconomicArea', label: 'If You are a Resident of the European Economic Area', id: 'eu-economic-area' },
                        { name: 'personalDataUse', label: 'By checking this box and pressing "Submit", I consent to Organogenesis\' use of my personal data for the purposes identified in the information I have provided in this form.', class: 'eu-economic-area' },
                        { name: 'marketingCommunications', label: 'By checking this box and pressing "Submit", I consent to Organogenesis\' use of my personal information for purposes of sending me marketing communications.', class: 'eu-economic-area' },
                        { name: 'internalAnalysis', label: 'By checking this box and pressing "Submit", I consent to Organogenesis\' use of my personal information for purposes of internal analysis.', class: 'eu-economic-area' },
                        { name: 'transferData', label: 'By checking this box and pressing "Submit" I consent to the transfer of my personal data to Organogenesis for the uses I have consented to above. I understand that Organogenesis is located in the United States, and that the European Commission has not determined that the federal and state laws of the United States provide EEA residents with an adequate level of data protection due to the country\'s surveillance laws. I understand that transfers of personal data to the United States involve risk, and consent to such risk by choosing to send my personal data to Organogenesis.', class: 'eu-economic-area' },
                    ]
                },
                'press': {
                    fields: [
                        { id: '#contact-title-container', name: 'namePrefix', validators: ['required'] },
                        { id: '#contact-first-name-container', name: 'firstName', validators: ['required'] },
                        { id: '#contact-last-name-container', name: 'lastName', validators: ['required'] },
                        { id: '#contact-email-container', name: 'email', validators: ['email'] },
                        { id: '#contact-phone-container', name: 'phone', validators: ['phone'] },
                        { id: '#contact-country-container', name: 'country', validators: ['required'] },
                        { id: '#contact-zip-code-container', name: 'zip', validators: ['zip'] },
                    ],
                    agreements: [
                        { name: 'contactRequest', label: 'I would like to be contacted by Organogenesis.' },
                        { name: 'shareInfo', label: 'I also agree to allow Organogenesis and its agents to collect the information provided and to be contacted by Organogenesis and its agents in the future regarding other products and services.' },
                        { name: 'euEconomicArea', label: 'If You are a Resident of the European Economic Area', id: 'eu-economic-area' },
                        { name: 'personalDataUse', label: 'By checking this box and pressing "Submit", I consent to Organogenesis\' use of my personal data for the purposes identified in the information I have provided in this form.', class: 'eu-economic-area' },
                        { name: 'marketingCommunications', label: 'By checking this box and pressing "Submit", I consent to Organogenesis\' use of my personal information for purposes of sending me marketing communications.', class: 'eu-economic-area' },
                        { name: 'internalAnalysis', label: 'By checking this box and pressing "Submit", I consent to Organogenesis\' use of my personal information for purposes of internal analysis.', class: 'eu-economic-area' },
                        { name: 'transferData', label: 'By checking this box and pressing "Submit" I consent to the transfer of my personal data to Organogenesis for the uses I have consented to above. I understand that Organogenesis is located in the United States, and that the European Commission has not determined that the federal and state laws of the United States provide EEA residents with an adequate level of data protection due to the country\'s surveillance laws. I understand that transfers of personal data to the United States involve risk, and consent to such risk by choosing to send my personal data to Organogenesis.', class: 'eu-economic-area' },
                    ]
                },
                'patient': {
                    fields: [
                        { id: '#contact-title-container', name: 'namePrefix', validators: ['required'] },
                        { id: '#contact-first-name-container', name: 'firstName', validators: ['required'] },
                        { id: '#contact-last-name-container', name: 'lastName', validators: ['required'] },
                        { id: '#contact-email-container', name: 'email', validators: ['email'] },
                        { id: '#contact-phone-container', name: 'phone', validators: ['phone'] },
                        { id: '#contact-country-container', name: 'country', validators: ['required'] },
                        { id: '#contact-zip-code-container', name: 'zip', validators: ['zip'] },
                    ],
                    agreements: [
                        { name: 'contactRequest', label: 'I would like to be contacted by Organogenesis.' },
                        { name: 'shareInfo', label: 'I authorize Organogenesis and those working with or on behalf of Organogenesis  to contact me via email, mail, or phone to conduct marketing activities, which includes, but is not limited to, providing me with educational and promotional materials, information on Organogenesis products and patient support services related to my treatment or health condition and/or to conduct market research activities, which includes contacting me to participate in various activities such as surveys, focus groups, or interviews which would be funded by Organogenesis. I understand that Organogenesis will not release my personally identifiable information to any party, except as provided in this authorization or as permitted by applicable law, without first obtaining my (or my authorized representative’s) separate written consent. I understand that I may revoke this authorization at any time by contacting Organogenesis at <a href="tel:+1 888 432 5232">+1 888 432 5232</a> or in writing at Organogenesis 85 Dan Road, Canton, MA 02021.' },
                        { name: 'euEconomicArea', label: 'If You are a Resident of the European Economic Area', id: 'eu-economic-area' },
                        { name: 'personalDataUse', label: 'By checking this box and pressing "Submit", I consent to Organogenesis\' use of my personal data for the purposes identified in the information I have provided in this form.', class: 'eu-economic-area' },
                        { name: 'marketingCommunications', label: 'By checking this box and pressing "Submit", I consent to Organogenesis\' use of my personal information for purposes of sending me marketing communications.', class: 'eu-economic-area' },
                        { name: 'internalAnalysis', label: 'By checking this box and pressing "Submit", I consent to Organogenesis\' use of my personal information for purposes of internal analysis.', class: 'eu-economic-area' },
                        { name: 'transferData', label: 'By checking this box and pressing "Submit" I consent to the transfer of my personal data to Organogenesis for the uses I have consented to above. I understand that Organogenesis is located in the United States, and that the European Commission has not determined that the federal and state laws of the United States provide EEA residents with an adequate level of data protection due to the country\'s surveillance laws. I understand that transfers of personal data to the United States involve risk, and consent to such risk by choosing to send my personal data to Organogenesis.', class: 'eu-economic-area' },
                    ]
                },
                'medical': {
                    fields: [
                        { id: '#contact-title-container', name: 'namePrefix', validators: ['required'] },
                        { id: '#contact-first-name-container', name: 'firstName', validators: ['required'] },
                        { id: '#contact-last-name-container', name: 'lastName', validators: ['required'] },
                        { id: '#contact-email-container', name: 'email', validators: ['email'] },
                        { id: '#contact-phone-container', name: 'phone', validators: ['phone'] },
                        { id: '#contact-country-container', name: 'country', validators: ['required'] },
                        { id: '#contact-zip-code-container', name: 'zip', validators: ['zip'] },
                    ],
                    agreements: [
                        { name: 'contactRequest', label: 'I would like to be contacted by Organogenesis.' },
                        { name: 'shareInfo', label: 'I also agree to allow Organogenesis and its agents to collect the information provided and to be contacted by Organogenesis and its agents in the future regarding other products and services.' },
                        { name: 'euEconomicArea', label: 'If You are a Resident of the European Economic Area', id: 'eu-economic-area' },
                        { name: 'personalDataUse', label: 'By checking this box and pressing "Submit", I consent to Organogenesis\' use of my personal data for the purposes identified in the information I have provided in this form.', class: 'eu-economic-area' },
                        { name: 'marketingCommunications', label: 'By checking this box and pressing "Submit", I consent to Organogenesis\' use of my personal information for purposes of sending me marketing communications.', class: 'eu-economic-area' },
                        { name: 'internalAnalysis', label: 'By checking this box and pressing "Submit", I consent to Organogenesis\' use of my personal information for purposes of internal analysis.', class: 'eu-economic-area' },
                        { name: 'transferData', label: 'By checking this box and pressing "Submit" I consent to the transfer of my personal data to Organogenesis for the uses I have consented to above. I understand that Organogenesis is located in the United States, and that the European Commission has not determined that the federal and state laws of the United States provide EEA residents with an adequate level of data protection due to the country\'s surveillance laws. I understand that transfers of personal data to the United States involve risk, and consent to such risk by choosing to send my personal data to Organogenesis.', class: 'eu-economic-area' },
                    ]
                },
                'regulatory': {
                    fields: [
                        { id: '#contact-title-container', name: 'namePrefix', validators: ['required'] },
                        { id: '#contact-first-name-container', name: 'firstName', validators: ['required'] },
                        { id: '#contact-last-name-container', name: 'lastName', validators: ['required'] },
                        { id: '#contact-email-container', name: 'email', validators: ['email'] },
                        { id: '#contact-phone-container', name: 'phone', validators: ['phone'] },
                        { id: '#contact-country-container', name: 'country', validators: ['required'] },
                        { id: '#contact-zip-code-container', name: 'zip', validators: ['zip'] },
                    ],
                    agreements: [
                        { name: 'contactRequest', label: 'I would like to be contacted by Organogenesis.' },
                        { name: 'shareInfo', label: 'I also agree to allow Organogenesis and its agents to collect the information provided and to be contacted by Organogenesis and its agents in the future regarding other products and services.' },
                        { name: 'euEconomicArea', label: 'If You are a Resident of the European Economic Area', id: 'eu-economic-area' },
                        { name: 'personalDataUse', label: 'By checking this box and pressing "Submit", I consent to Organogenesis\' use of my personal data for the purposes identified in the information I have provided in this form.', class: 'eu-economic-area' },
                        { name: 'marketingCommunications', label: 'By checking this box and pressing "Submit", I consent to Organogenesis\' use of my personal information for purposes of sending me marketing communications.', class: 'eu-economic-area' },
                        { name: 'internalAnalysis', label: 'By checking this box and pressing "Submit", I consent to Organogenesis\' use of my personal information for purposes of internal analysis.', class: 'eu-economic-area' },
                        { name: 'transferData', label: 'By checking this box and pressing "Submit" I consent to the transfer of my personal data to Organogenesis for the uses I have consented to above. I understand that Organogenesis is located in the United States, and that the European Commission has not determined that the federal and state laws of the United States provide EEA residents with an adequate level of data protection due to the country\'s surveillance laws. I understand that transfers of personal data to the United States involve risk, and consent to such risk by choosing to send my personal data to Organogenesis.', class: 'eu-economic-area' },
                    ]
                },
                'customer-service': {
                    fields: [
                        { id: '#contact-title-container', name: 'namePrefix', validators: ['required'] },
                        { id: '#contact-first-name-container', name: 'firstName', validators: ['required'] },
                        { id: '#contact-last-name-container', name: 'lastName', validators: ['required'] },
                        { id: '#contact-institution-affiliation-container', name: 'institution', validators: ['required'] },
                        { id: '#contact-customer-account-number-container', name: 'customerAccountNumber' },
                        { id: '#contact-email-container', name: 'email', validators: ['email'] },
                        { id: '#contact-phone-container', name: 'phone', validators: ['phone'] },
                        { id: '#contact-zip-code-container', name: 'zip', validators: ['zip'] },
                    ],
                    agreements: [
                        { name: 'contactRequest', label: 'I would like to be contacted by Organogenesis.' },
                        { name: 'shareInfo', label: 'I also agree to allow Organogenesis and its agents to collect the information provided and to be contacted by Organogenesis and its agents in the future regarding other products and services.' },
                        { name: 'euEconomicArea', label: 'If You are a Resident of the European Economic Area', id: 'eu-economic-area' },
                        { name: 'personalDataUse', label: 'By checking this box and pressing "Submit", I consent to Organogenesis\' use of my personal data for the purposes identified in the information I have provided in this form.', class: 'eu-economic-area' },
                        { name: 'marketingCommunications', label: 'By checking this box and pressing "Submit", I consent to Organogenesis\' use of my personal information for purposes of sending me marketing communications.', class: 'eu-economic-area' },
                        { name: 'internalAnalysis', label: 'By checking this box and pressing "Submit", I consent to Organogenesis\' use of my personal information for purposes of internal analysis.', class: 'eu-economic-area' },
                        { name: 'transferData', label: 'By checking this box and pressing "Submit" I consent to the transfer of my personal data to Organogenesis for the uses I have consented to above. I understand that Organogenesis is located in the United States, and that the European Commission has not determined that the federal and state laws of the United States provide EEA residents with an adequate level of data protection due to the country\'s surveillance laws. I understand that transfers of personal data to the United States involve risk, and consent to such risk by choosing to send my personal data to Organogenesis.', class: 'eu-economic-area' },
                    ]
                },
            },
        },
        'not-hcp': {
            'area-of-interest': [
                { value: 'compliance', label: 'Compliance' },
                { value: 'corporate', label: 'Corporate' },
                { value: 'investor', label: 'Investor' },
                { value: 'press', label: 'Press' },
                { value: 'patient', label: 'Patient' },
                { value: 'medical', label: 'Medical' },
                { value: 'regulatory', label: 'Regulatory' },
            ],
            scenarios: {
                '': [],
                'compliance': {
                    fields: [
                        { id: '#contact-title-container', name: 'namePrefix', validators: ['required'] },
                        { id: '#contact-first-name-container', name: 'firstName', validators: ['required'] },
                        { id: '#contact-last-name-container', name: 'lastName', validators: ['required'] },
                        { id: '#contact-email-container', name: 'email', validators: ['email'] },
                        { id: '#contact-phone-container', name: 'phone', validators: ['phone'] },
                        { id: '#contact-country-container', name: 'country', validators: ['required'] },
                        { id: '#contact-zip-code-container', name: 'zip', validators: ['zip'] },
                    ],
                    disclaimers: [
                        '<div>General Compliance Helpline & Email <a href="tel:800-598-6936">800-598-6936</a>; <a href="mailto:compliance@organo.com">compliance@organo.com</a></div><br /><div>- <strong>Note</strong>: Inquiries sent to this email address will not be done so anonymously.</div><br /><div>Anonymous Compliance Helpline: <a href="tel:833-880-0007">833-880-0007</a>; <a href="mailto:reports@lighthouse-services.com">reports@lighthouse-services.com</a>; <a target="_blank" href="https://lighthouse-services.com/organo">www.lighthouse-services.com/organo</a></div>'
                    ],
                    agreements: [
                        { name: 'contactRequest', label: 'I would like to be contacted by Organogenesis.' },
                        { name: 'shareInfo', label: 'I also agree to allow Organogenesis and its agents to collect the information provided and to be contacted by Organogenesis and its agents in the future regarding other products and services.' },
                        { name: 'euEconomicArea', label: 'If You are a Resident of the European Economic Area', id: 'eu-economic-area' },
                        { name: 'personalDataUse', label: 'By checking this box and pressing "Submit", I consent to Organogenesis\' use of my personal data for the purposes identified in the information I have provided in this form.', class: 'eu-economic-area' },
                        { name: 'marketingCommunications', label: 'By checking this box and pressing "Submit", I consent to Organogenesis\' use of my personal information for purposes of sending me marketing communications.', class: 'eu-economic-area' },
                        { name: 'internalAnalysis', label: 'By checking this box and pressing "Submit", I consent to Organogenesis\' use of my personal information for purposes of internal analysis.', class: 'eu-economic-area' },
                        { name: 'transferData', label: 'By checking this box and pressing "Submit" I consent to the transfer of my personal data to Organogenesis for the uses I have consented to above. I understand that Organogenesis is located in the United States, and that the European Commission has not determined that the federal and state laws of the United States provide EEA residents with an adequate level of data protection due to the country\'s surveillance laws. I understand that transfers of personal data to the United States involve risk, and consent to such risk by choosing to send my personal data to Organogenesis.', class: 'eu-economic-area' },
                    ]
                },
                'corporate': {
                    fields: [
                        { id: '#contact-title-container', name: 'namePrefix', validators: ['required'] },
                        { id: '#contact-first-name-container', name: 'firstName', validators: ['required'] },
                        { id: '#contact-last-name-container', name: 'lastName', validators: ['required'] },
                        { id: '#contact-email-container', name: 'email', validators: ['email'] },
                        { id: '#contact-phone-container', name: 'phone', validators: ['phone'] },
                        { id: '#contact-country-container', name: 'country', validators: ['required'] },
                        { id: '#contact-zip-code-container', name: 'zip', validators: ['zip'] },
                    ],
                    agreements: [
                        { name: 'contactRequest', label: 'I would like to be contacted by Organogenesis.' },
                        { name: 'shareInfo', label: 'I also agree to allow Organogenesis and its agents to collect the information provided and to be contacted by Organogenesis and its agents in the future regarding other products and services.' },
                        { name: 'euEconomicArea', label: 'If You are a Resident of the European Economic Area', id: 'eu-economic-area' },
                        { name: 'personalDataUse', label: 'By checking this box and pressing "Submit", I consent to Organogenesis\' use of my personal data for the purposes identified in the information I have provided in this form.', class: 'eu-economic-area' },
                        { name: 'marketingCommunications', label: 'By checking this box and pressing "Submit", I consent to Organogenesis\' use of my personal information for purposes of sending me marketing communications.', class: 'eu-economic-area' },
                        { name: 'internalAnalysis', label: 'By checking this box and pressing "Submit", I consent to Organogenesis\' use of my personal information for purposes of internal analysis.', class: 'eu-economic-area' },
                        { name: 'transferData', label: 'By checking this box and pressing "Submit" I consent to the transfer of my personal data to Organogenesis for the uses I have consented to above. I understand that Organogenesis is located in the United States, and that the European Commission has not determined that the federal and state laws of the United States provide EEA residents with an adequate level of data protection due to the country\'s surveillance laws. I understand that transfers of personal data to the United States involve risk, and consent to such risk by choosing to send my personal data to Organogenesis.', class: 'eu-economic-area' },
                    ]
                },
                'investor': {
                    fields: [
                        { id: '#contact-title-container', name: 'namePrefix', validators: ['required'] },
                        { id: '#contact-first-name-container', name: 'firstName', validators: ['required'] },
                        { id: '#contact-last-name-container', name: 'lastName', validators: ['required'] },
                        { id: '#contact-email-container', name: 'email', validators: ['email'] },
                        { id: '#contact-phone-container', name: 'phone', validators: ['phone'] },
                        { id: '#contact-country-container', name: 'country', validators: ['required'] },
                        { id: '#contact-zip-code-container', name: 'zip', validators: ['zip'] },
                    ],
                    agreements: [
                        { name: 'contactRequest', label: 'I would like to be contacted by Organogenesis.' },
                        { name: 'shareInfo', label: 'I also agree to allow Organogenesis and its agents to collect the information provided and to be contacted by Organogenesis and its agents in the future regarding other products and services.' },
                        { name: 'euEconomicArea', label: 'If You are a Resident of the European Economic Area', id: 'eu-economic-area' },
                        { name: 'personalDataUse', label: 'By checking this box and pressing "Submit", I consent to Organogenesis\' use of my personal data for the purposes identified in the information I have provided in this form.', class: 'eu-economic-area' },
                        { name: 'marketingCommunications', label: 'By checking this box and pressing "Submit", I consent to Organogenesis\' use of my personal information for purposes of sending me marketing communications.', class: 'eu-economic-area' },
                        { name: 'internalAnalysis', label: 'By checking this box and pressing "Submit", I consent to Organogenesis\' use of my personal information for purposes of internal analysis.', class: 'eu-economic-area' },
                        { name: 'transferData', label: 'By checking this box and pressing "Submit" I consent to the transfer of my personal data to Organogenesis for the uses I have consented to above. I understand that Organogenesis is located in the United States, and that the European Commission has not determined that the federal and state laws of the United States provide EEA residents with an adequate level of data protection due to the country\'s surveillance laws. I understand that transfers of personal data to the United States involve risk, and consent to such risk by choosing to send my personal data to Organogenesis.', class: 'eu-economic-area' },
                    ]
                },
                'press': {
                    fields: [
                        { id: '#contact-title-container', name: 'namePrefix', validators: ['required'] },
                        { id: '#contact-first-name-container', name: 'firstName', validators: ['required'] },
                        { id: '#contact-last-name-container', name: 'lastName', validators: ['required'] },
                        { id: '#contact-email-container', name: 'email', validators: ['email'] },
                        { id: '#contact-phone-container', name: 'phone', validators: ['phone'] },
                        { id: '#contact-country-container', name: 'country', validators: ['required'] },
                        { id: '#contact-zip-code-container', name: 'zip', validators: ['zip'] },
                    ],
                    agreements: [
                        { name: 'contactRequest', label: 'I would like to be contacted by Organogenesis.' },
                        { name: 'shareInfo', label: 'I also agree to allow Organogenesis and its agents to collect the information provided and to be contacted by Organogenesis and its agents in the future regarding other products and services.' },
                        { name: 'euEconomicArea', label: 'If You are a Resident of the European Economic Area', id: 'eu-economic-area' },
                        { name: 'personalDataUse', label: 'By checking this box and pressing "Submit", I consent to Organogenesis\' use of my personal data for the purposes identified in the information I have provided in this form.', class: 'eu-economic-area' },
                        { name: 'marketingCommunications', label: 'By checking this box and pressing "Submit", I consent to Organogenesis\' use of my personal information for purposes of sending me marketing communications.', class: 'eu-economic-area' },
                        { name: 'internalAnalysis', label: 'By checking this box and pressing "Submit", I consent to Organogenesis\' use of my personal information for purposes of internal analysis.', class: 'eu-economic-area' },
                        { name: 'transferData', label: 'By checking this box and pressing "Submit" I consent to the transfer of my personal data to Organogenesis for the uses I have consented to above. I understand that Organogenesis is located in the United States, and that the European Commission has not determined that the federal and state laws of the United States provide EEA residents with an adequate level of data protection due to the country\'s surveillance laws. I understand that transfers of personal data to the United States involve risk, and consent to such risk by choosing to send my personal data to Organogenesis.', class: 'eu-economic-area' },
                    ]
                },
                'patient': {
                    fields: [
                        { id: '#contact-title-container', name: 'namePrefix', validators: ['required'] },
                        { id: '#contact-first-name-container', name: 'firstName', validators: ['required'] },
                        { id: '#contact-last-name-container', name: 'lastName', validators: ['required'] },
                        { id: '#contact-email-container', name: 'email', validators: ['email'] },
                        { id: '#contact-phone-container', name: 'phone', validators: ['phone'] },
                        { id: '#contact-country-container', name: 'country', validators: ['required'] },
                        { id: '#contact-zip-code-container', name: 'zip', validators: ['zip'] },
                    ],
                    agreements: [
                        { name: 'contactRequest', label: 'I would like to be contacted by Organogenesis.' },
                        { name: 'shareInfo', label: 'I authorize Organogenesis and those working with or on behalf of Organogenesis to contact me via email, mail, or phone to conduct marketing activities, which includes, but is not limited to, providing me with educational and promotional materials, information on Organogenesis products and patient support services related to my treatment or health condition and/or to conduct market research activities, which includes contacting me to participate in various activities such as surveys, focus groups, or interviews which would be funded by Organogenesis. I understand that Organogenesis will not release my personally identifiable information to any party, except as provided in this authorization or as permitted by applicable law, without first obtaining my (or my authorized representative’s) separate written consent. I understand that I may revoke this authorization at any time by contacting Organogenesis at <a href="tel:+1 888 432 5232">+1 888 432 5232</a> or in writing at Organogenesis 85 Dan Road, Canton, MA 02021.' },
                        { name: 'euEconomicArea', label: 'If You are a Resident of the European Economic Area', id: 'eu-economic-area' },
                        { name: 'personalDataUse', label: 'By checking this box and pressing "Submit", I consent to Organogenesis\' use of my personal data for the purposes identified in the information I have provided in this form.', class: 'eu-economic-area' },
                        { name: 'marketingCommunications', label: 'By checking this box and pressing "Submit", I consent to Organogenesis\' use of my personal information for purposes of sending me marketing communications.', class: 'eu-economic-area' },
                        { name: 'internalAnalysis', label: 'By checking this box and pressing "Submit", I consent to Organogenesis\' use of my personal information for purposes of internal analysis.', class: 'eu-economic-area' },
                        { name: 'transferData', label: 'By checking this box and pressing "Submit" I consent to the transfer of my personal data to Organogenesis for the uses I have consented to above. I understand that Organogenesis is located in the United States, and that the European Commission has not determined that the federal and state laws of the United States provide EEA residents with an adequate level of data protection due to the country\'s surveillance laws. I understand that transfers of personal data to the United States involve risk, and consent to such risk by choosing to send my personal data to Organogenesis.', class: 'eu-economic-area' },
                    ]
                },
                'medical': {
                    fields: [
                        { id: '#contact-title-container', name: 'namePrefix', validators: ['required'] },
                        { id: '#contact-first-name-container', name: 'firstName', validators: ['required'] },
                        { id: '#contact-last-name-container', name: 'lastName', validators: ['required'] },
                        { id: '#contact-email-container', name: 'email', validators: ['email'] },
                        { id: '#contact-phone-container', name: 'phone', validators: ['phone'] },
                        { id: '#contact-country-container', name: 'country', validators: ['required'] },
                        { id: '#contact-zip-code-container', name: 'zip', validators: ['zip'] },
                    ],
                    agreements: [
                        { name: 'contactRequest', label: 'I would like to be contacted by Organogenesis.' },
                        { name: 'shareInfo', label: 'I also agree to allow Organogenesis and its agents to collect the information provided and to be contacted by Organogenesis and its agents in the future regarding other products and services.' },
                        { name: 'euEconomicArea', label: 'If You are a Resident of the European Economic Area', id: 'eu-economic-area' },
                        { name: 'personalDataUse', label: 'By checking this box and pressing "Submit", I consent to Organogenesis\' use of my personal data for the purposes identified in the information I have provided in this form.', class: 'eu-economic-area' },
                        { name: 'marketingCommunications', label: 'By checking this box and pressing "Submit", I consent to Organogenesis\' use of my personal information for purposes of sending me marketing communications.', class: 'eu-economic-area' },
                        { name: 'internalAnalysis', label: 'By checking this box and pressing "Submit", I consent to Organogenesis\' use of my personal information for purposes of internal analysis.', class: 'eu-economic-area' },
                        { name: 'transferData', label: 'By checking this box and pressing "Submit" I consent to the transfer of my personal data to Organogenesis for the uses I have consented to above. I understand that Organogenesis is located in the United States, and that the European Commission has not determined that the federal and state laws of the United States provide EEA residents with an adequate level of data protection due to the country\'s surveillance laws. I understand that transfers of personal data to the United States involve risk, and consent to such risk by choosing to send my personal data to Organogenesis.', class: 'eu-economic-area' },
                    ]
                },
                'regulatory': {
                    fields: [
                        { id: '#contact-title-container', name: 'namePrefix', validators: ['required'] },
                        { id: '#contact-first-name-container', name: 'firstName', validators: ['required'] },
                        { id: '#contact-last-name-container', name: 'lastName', validators: ['required'] },
                        { id: '#contact-email-container', name: 'email', validators: ['email'] },
                        { id: '#contact-phone-container', name: 'phone', validators: ['phone'] },
                        { id: '#contact-country-container', name: 'country', validators: ['required'] },
                        { id: '#contact-zip-code-container', name: 'zip', validators: ['zip'] },
                    ],
                    agreements: [
                        { name: 'contactRequest', label: 'I would like to be contacted by Organogenesis.' },
                        { name: 'shareInfo', label: 'I also agree to allow Organogenesis and its agents to collect the information provided and to be contacted by Organogenesis and its agents in the future regarding other products and services.' },
                        { name: 'euEconomicArea', label: 'If You are a Resident of the European Economic Area', id: 'eu-economic-area' },
                        { name: 'personalDataUse', label: 'By checking this box and pressing "Submit", I consent to Organogenesis\' use of my personal data for the purposes identified in the information I have provided in this form.', class: 'eu-economic-area' },
                        { name: 'marketingCommunications', label: 'By checking this box and pressing "Submit", I consent to Organogenesis\' use of my personal information for purposes of sending me marketing communications.', class: 'eu-economic-area' },
                        { name: 'internalAnalysis', label: 'By checking this box and pressing "Submit", I consent to Organogenesis\' use of my personal information for purposes of internal analysis.', class: 'eu-economic-area' },
                        { name: 'transferData', label: 'By checking this box and pressing "Submit" I consent to the transfer of my personal data to Organogenesis for the uses I have consented to above. I understand that Organogenesis is located in the United States, and that the European Commission has not determined that the federal and state laws of the United States provide EEA residents with an adequate level of data protection due to the country\'s surveillance laws. I understand that transfers of personal data to the United States involve risk, and consent to such risk by choosing to send my personal data to Organogenesis.', class: 'eu-economic-area' },
                    ]
                },
            },
        },
    }
};
var FORM_TYPE = 'product';
var PRODUCT = 'nushield';

jQuery(function() {
    if ($('#get-in-contact-form').length) {
        $('#contact-preferred-method-of-contact-container')['preferred-method-of-contact']();
        $('#contact-sign-up-for-email-options-container')['sign-up-for-email-options']();
        var GetInContactForm = function(selector) {
            ContactForm.call(this, selector);
            var hostname = window.location.hostname;
            this.dataUrl = 'https://leads.organogenesis.dev/webform_rest/submit';
            this.dataAPIKey = 'PsAxqib6hr3anKOn3eouHHw7Hi3yFyrK';
            this.emailUrl = 'https://leads.organogenesis.dev/webform_rest/submit';
            this.emailAPIKey = 'DShuQTBjQjJrYeX5DsX83cjX3BzTXQTNB';

            if (/^nushieldcomplete\.com$/.test(hostname) || /^www\.nushieldcomplete\.com$/.test(hostname)) {
                this.dataUrl = 'https://leads.organogenesis.com/webform_rest/submit';
                this.emailUrl = 'https://leads.organogenesis.com/webform_rest/submit';
            } else if (/^nushieldcomplete\.dev$/.test(hostname) || /^www\.nushieldcomplete\.dev$/.test(hostname)) {
                this.dataUrl = 'https://leads.organogenesis.dev/webform_rest/submit';
                this.emailUrl = 'https://leads.organogenesis.dev/webform_rest/submit';
            } else if (/^sfsk-nushieldcomplete-ssi\.lndo\.site$/.test(hostname)) {
                this.dataUrl = 'https://sfsk-organogenesis-leads-d10.lndo.site/webform_rest/submit';
                this.emailUrl = 'https://sfsk-organogenesis-leads-d10.lndo.site/webform_rest/submit';
            }

            this.$fields = $('.scenario-container')['scenario-input']();
            var scenarioFields = {};
            $.each(this.$fields, function(i, field) {
                var widget = $(field)['scenario-input']().data('ognScenarioInput');
                scenarioFields[widget.hashID] = widget;
            });
            this.scenarioFields = scenarioFields;
            this.scenario = [];
            this.agreements = [];

            this.formType = FORM_TYPE;
            this.iAmValue = '';
            this.$areaOfIntrestSelect = $('#contact-area-of-interest-input', selector);
            this.$areaOfIntrestSelect.on('change', this.onChangeAreaOfInterest.bind(this));
            this.$userTypeRadio = $('input[name="userType"]', selector);
            this.$userTypeRadio.on('change', this.onChangeUserType.bind(this));
        };
        GetInContactForm.prototype = Object.create(ContactForm.prototype);
        GetInContactForm.prototype.constructor = GetInContactForm;
        GetInContactForm.prototype.onChangeUserType = function(event) {
            event.preventDefault();
            this.beforeChangeScenario();
            var $current = $(event.currentTarget);
            var $form = $('.form-holder', this.selector);
            if ($current.is(':checked')) {
                this.iAmValue = $current.val();
                var interestsOptions = formScenarios[FORM_TYPE][this.iAmValue]['area-of-interest'].map(function(item) {
                    return $('<option>').attr('value', item.value).html(item.label);
                });
                this.$areaOfIntrestSelect.html('').append(interestsOptions);

                this.$areaOfIntrestSelect.dropdownselect('rebuild');
                $form.addClass('visible');
            } else {
                $form.removeClass('visible');
            }
        }
        GetInContactForm.prototype.onChangeAreaOfInterest = function(event) {
            event.preventDefault();
            this.beforeChangeScenario();
            var value = $(event.currentTarget).val();
            var scenario = formScenarios[FORM_TYPE][this.iAmValue]['scenarios'][value];
            if (!scenario) {
                scenario = { fields: [], agreements: [] };
            }
            scenario['fields'].forEach(this.showScenarioField.bind(this));
            scenario['agreements'].forEach(this.insertAgreement.bind(this));
            this.$euEconomicArea = $('.eu-economic-area');
            this.$euEconomicAreaCheckboxes = $('.eu-economic-area input[type="checkbox"]');
            this.$generalAgreementCheckboxes = $('.scenario-agreement:not(.eu-economic-area):not(#eu-economic-area) input[type="checkbox"]');
            this.$euEconomicArea.on('hide', this.onHideEUEconomicArea.bind(this));
            this.$euEconomicAreaToggler = $('#eu-economic-area input[type="checkbox"]');
            this.$euEconomicAreaToggler.on('change', this.onChangeEUEconomicArea.bind(this));
            $('.scenario-agreement input[type="checkbox"]').on('change', this.onChangeAgreementValue.bind(this));

            if (scenario['disclaimers']) {
                scenario['disclaimers'].forEach(this.insertDisclaimers.bind(this));
            }

            $('.form-submit').show();
            $('#contact-disclaimer').show();
        }
        GetInContactForm.prototype.onChangeAgreementValue = function(event) {
            if (event.target !== event.currentTarget) {
                return;
            }
            var $button = $('#universal-contact-submit');
            var isButtonDisabled = false;
            isButtonDisabled = this.$generalAgreementCheckboxes.map(function() {
                return $(this).is(':checked');
            }).get().some(function(isChecked) {
                return !isChecked;
            });
            if (isButtonDisabled) {
                $button.prop('disabled', true);
                return;
            }
            if (this.$euEconomicAreaToggler.is(':checked')) {
                isButtonDisabled = this.$euEconomicAreaCheckboxes.map(function() {
                    return $(this).is(':checked');
                }).get().some(function(isChecked) {
                    return !isChecked;
                });
            }
            if (isButtonDisabled) {
                $button.prop('disabled', true);
                return;
            }
            $button.prop('disabled', false);
        }
        GetInContactForm.prototype.onHideEUEconomicArea = function(event) {
            if (event.target !== event.currentTarget) {
                return;
            }
            $('input', event.target).prop('checked', false);
        }
        GetInContactForm.prototype.onChangeEUEconomicArea = function(event) {
            if (event.target !== event.currentTarget) {
                return;
            }
            if ($(event.target).is(':checked')) {
                this.$euEconomicArea.show();
            } else {
                this.$euEconomicArea.hide();
            }
        };
        GetInContactForm.prototype.beforeChangeScenario = function() {
            this.errors = false;
            this.$form.removeClass('has-errors');
            this.$fields.hide();
            this.scenario = [];
            this.agreements = [];
            $('.scenario-disclaimer').remove();
            $('.scenario-agreement').remove();
            $('.form-submit').hide();
            $('#contact-disclaimer').hide();
            $('#universal-contact-submit').prop('disabled', true);
        }
        GetInContactForm.prototype.showScenarioField = function(options) {
            var $field = this.scenarioFields[options.id];
            if ($field) {
                this.scenario.push($field);
                $field.update(options);
                $field.show();
            }
        }
        GetInContactForm.prototype.insertDisclaimers = function(disclaimer, index) {
            var id = 'contact-scenario-disclaimer-'  + index;
            if ($('#' + id, this.selector).length) return;
            $('<p>').attr('id', id).addClass('scenario-disclaimer').html(disclaimer).insertAfter('#contact-disclaimer');
        }
        GetInContactForm.prototype.insertAgreement = function(agreement, index, agreements) {
            if ($('#' + agreement.name, this.selector).length) return;
            var $agreementContainer = $('<div>').addClass('form-group form-check custom-checkbox scenario-agreement');
            if (agreement.class) {
                $agreementContainer.addClass(agreement.class);
            }
            if (agreement.id) {
                $agreementContainer.attr('id', agreement.id);
            }
            if (agreement.class && agreement.class === 'eu-economic-area') {
                $agreementContainer.css('display', 'none');
            }
            if (!agreement.id && !agreement.label.includes('<sup')) {
                agreement.label += '<sup class="required">*</sup>';
            }
            var $agreement = $('<input>')
                .attr('name', agreement.name)
                .attr('type', 'checkbox')
                .attr('id', agreement.name)
                .addClass('form-check-input mt-0')
                .appendTo($agreementContainer);
            this.agreements.push($agreement);
            $('<label>')
                .attr('for', agreement.name)
                .addClass('form-check-label white')
                .append(agreement.label)
                .appendTo($agreementContainer);
            $agreementContainer.appendTo('#contact-confirmation-checkboxes');
        }

        GetInContactForm.prototype.serialize = function() {
            var formData = this.scenario.reduce(this.onFieldSerialize.bind(this), {});
            var origin = window.location.href;
            if (origin.indexOf('#') > -1) {
                origin = origin.substring(0, origin.indexOf('#'));
            }
            formData.origin = origin;
            // Provide the required parameter webform_id for the correct form submission.
            formData.webform_id = 'nushieldcomplete_contact';
            var userTypes = {
                'hcp': 'US HCP',
                'not-hcp': 'Not US HCP'
            };
            formData['userType'] = userTypes[this.iAmValue];
            // Extract area of interest
            var areaOfInterestCode = this.$areaOfIntrestSelect.val();
            var scenario = formScenarios[FORM_TYPE][this.iAmValue]['scenarios'][areaOfInterestCode];
            var areaOfInterest = formScenarios[FORM_TYPE][this.iAmValue]['area-of-interest'].find(function(element) {
                return element.value === areaOfInterestCode;
            })
            formData['areaOfInterest'] = areaOfInterest ? areaOfInterest['label'] : areaOfInterestCode;
            // Extract specialty
            var specialties = scenario.fields.find(function(element) {
                return element.name === 'specialty'
            });
            if (formData['specialty'] && specialties) {
                var specialty = specialties.variants.find(function(element) {
                    return element.value === formData['specialty'];
                });
                formData['specialty'] = specialty ? specialty.label : formData['specialty'];
            }
            // Extract Reason for contact
            var contactReasons = scenario.fields.find(function(element) {
                return element.name === 'contactReason';
            });
            if (formData['contactReason'] && contactReasons) {
                var contactReason = contactReasons.variants.find(function(element) {
                    return element.value === formData['contactReason'];
                });
                formData['contactReason'] = contactReason ? contactReason.label : formData['contactReason'];
            }

            var agreements = this.$generalAgreementCheckboxes.get().reduce(function(results, checkbox) {
                var $checkbox = $(checkbox);
                results[$checkbox.attr('name')] = $checkbox.is(':checked');
                return results;
            }, {});
            agreements[this.$euEconomicAreaToggler.attr('name')] = this.$euEconomicAreaToggler.is(':checked');
            if (this.$euEconomicAreaToggler.is(':checked')) {
                agreements = this.$euEconomicAreaCheckboxes.get().reduce(function(results, checkbox) {
                    var $checkbox = $(checkbox);
                    results[$checkbox.attr('name')] = $checkbox.is(':checked');
                    return results;
                }, agreements);
            }
            formData = Object.assign(formData, agreements);
            return formData;
        };
        GetInContactForm.prototype.onFieldSerialize = function(formData, $field) {
            var data = $field.serialize();
            return Object.assign(formData, data);
        }
        GetInContactForm.prototype.request = function($form, success, error) {
            this.save($form).then(success, error);
        };
        GetInContactForm.prototype.containerSuccess = function() {
            dataLayer.push({'event':'Get in contact Form Submission', 'specialty_value':CONTACT_FORM_SPECIALTIES[$('select[name="specialty"]').val()]});
            var container = $(this);
            container.addClass('success');
            $('html,body').animate({ scrollTop: 0 }, 'slow');

            var $userTypeRadio = $('#get-in-contact-form input[name="userType"]');
            $userTypeRadio.removeAttr('checked').trigger('change');
            $('#get-in-contact-form form').trigger('reset');
            setTimeout(function() {
                container.removeClass('success');
            }, 10000)
        }
        GetInContactForm.prototype.validate = function() {
            this.errors = false;
            this.scenario.forEach(this.onFieldValidate.bind(this))
        }
        GetInContactForm.prototype.onFieldValidate = function($field) {
            var isInvalid = !$field.validate();
            if (isInvalid) {
                this.errors = true;
            }
        }
        GetInContactForm.prototype.onValidationError = function() {
            ContactForm.prototype.onValidationError.call(this);
            $('html,body').animate({ scrollTop: ($('form', this.selector).offset().top - offsetNavigationHeight) }, 'slow');
        }

        new GetInContactForm('#get-in-contact-form');
    }
});
